@layer base, mantine, components;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');

html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
  line-height: 1.2;
}

a {
  text-decoration: none;
  font-family: var(--font-inter);
}

p {
  padding: 0;
  margin: 0;
  font-family: var(--font-inter);
  line-height: 1.1;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Brand color */
  --brand-color-50: #F9F5FF;
  --brand-color-100: #F4EBFF;
  --brand-color-200: #E9D7FE;
  --brand-color-300: #D6BBFB;
  --brand-color-400: #B692F6;
  --brand-color-500: #9E77ED;
  --brand-color-600: #7F56D9;
  --brand-color-700: #6941C6;
  --brand-color-800: #53389E;
  --brand-color-900: #42307D;

  /* Brand secondary color */
  --brand-secondary-color: #E7BCA1;

  /* Gray color */
  --gray-color-50: #F9FAFB;
  --gray-color-100: #F2F4F7;
  --gray-color-200: #EAECF0;
  --gray-color-300: #D0D5DD;
  --gray-color-400: #98A2B3;
  --gray-color-500: #667085;
  --gray-color-600: #475467;
  --gray-color-700: #344054;
  --gray-color-800: #182230;
  --gray-color-900: #101828;

  /* Text color */
  --text-primary: var(--gray-color-900);
  --text-secondary: var(--gray-color-700);
  --text-tertiary: var(--gray-color-600);
  --text-quaternary: var(--gray-color-500);
  --text-primary-on-brand: white;
  --text-secondary-on-brand: var(--brand-color-200);
  --text-tertiary-on-brand: var(--brand-color-200);
  --text-quaternary-on-brand: var(--brand-color-300);
  --text-brand-primary: var(--brand-color-900);
  --text-brand-secondary: var(--brand-color-700);
  --text-brand-tertiary: var(--brand-color-600);

  /* Border color */
  --border-primary: var(--gray-color-300);
  --border-secondary: var(--gray-color-200);
  --border-tertiary: var(--gray-color-100);
  --border-brand: var(--brand-color-300);
  --border-brand-solid: var(--brand-color-600);

  /* Background color */
  --bg-primary: white;
  --bg-secondary: var(--gray-color-50);
  --bg-quaternary: var(--gray-color-200);
  --bg-brand-primary: var(--brand-color-50);
  --bg-brand-solid: var(--brand-color-600);
  --bg-brand-solid-hover: var(--brand-color-700);
  --bg-brand-section: var(--brand-color-800);

  /* Warning color */
  --warning-color-100: #FEF0C7;
  --warning-color-200: #FEDF89;
  --warning-color-300: #FEC84B;
  --warning-color-400: #FDB022;
  --warning-color-500: #F79009;
  --warning-color-600: #DC6803;
  --warning-color-700: #B54708;
  --warning-color-800: #93370D;
  --warning-color-900: #7A2E0E;

  /* Font size */
  --display-2xl: 72px;
  --display-xl: 60px;
  --display-lg: 48px;
  --display-md: 36px;
  --display-sm: 30px;
  --display-xs: 24px;
  --text-xl: 20px;
  --text-lg: 18px;
  --text-md: 16px;
  --text-sm: 14px;
  --text-xs: 12px;

  /* Font weight */
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
}

[id].section {
  padding-top: 6.25rem;
  margin-top: -6.25rem;
}

:target {
  padding-top: 6.25rem;
  margin-top: -6.25rem;
}
